<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="pastZakaat && pastZakaat.length > 0">
      <!-- TABLE HEADING -->
      <div class="row mx-0 justify-content-center">
        <div class="col-12 col-lg-10 col-xl-9 px-0">
          <!--DESKTOP-->
          <div class="row font13 green-text-dark d-none d-md-flex">
            <div class="col-md bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                NAME
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                DATE
              </div>
            </div>
            <div class="col-md-3 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                ZAKAAH DONATED/PAID
              </div>
            </div>
            <div class="col-md-3 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                INFO
              </div>
            </div>
          </div>
          <!--END DESKTOP-->
          <!--MOBILE-->
          <div class="row font13 green-text-dark d-md-none">
            <div class="col-3 bold text-center px-0">
              Name
            </div>
            <div class="col-3 bold text-center px-0">
              Date
            </div>
            <div class="col-3 bold text-center px-0">
              Donated
            </div>
            <div class="col-3 bold text-center px-0">
              Info
            </div>
          </div>
          <!--END MOBILE-->
        </div>
      </div>
      <!-- END TABLE HEADING -->
      <ManageZakaahItem v-for="(zakaat, key) in pastZakaat.slice(start, finish)" :key="key" :item="zakaat" :thekey="key" @showDetails="showDetails(key)" @deleteOrder="deleteOrder(zakaat.zakaatDetailID)" />
      <transition name="slideup">
      <ManageZakaahViewPopup v-if="currentItem" :item="currentItem" :theKey="currentKey" :totalItems="pastZakaat.length" @close="closeDetails"  @goForward="goForward(currentKey)" @goBack="goBack(currentKey)" @deleteOrder="deleteOrder(currentItem.zakaatDetailID)" />
      </transition>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-5" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
    <div class="col-12 p-0  my-3 text-center" v-else>
      You don't have any Zakaah Calculations.
    </div>
    <transition name="fade">
      <Popup @close="close" v-if="isDeleteItem">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Delete Zakaah Calculation
          </div>
          <div class="col-12 text-center mb-3">
            Are you sure you would like to delete this Zakaah Calculation?
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="grey" btnText="Keep Calculation" width="100" @buttonClicked="close" />
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="red" btnText="Delete" width="100" @buttonClicked="reallyDelete" :disabled="isDeleting" :icon="isDeleting" :isLoading="isDeleting">
            </Button>
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    ManageZakaahItem: defineAsyncComponent(() => import('./ManageZakaahItem.vue')),
    ManageZakaahViewPopup: defineAsyncComponent(() => import('./ManageZakaahViewPopup.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue'))
  },
  name: 'ManageZakaahTable',
  data () {
    return {
      itemsPerPage: 10,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      isDelete: false,
      currentID: null,
      isDeleteItem: false,
      isDeleting: false
    }
  },
  computed: {
    ...mapGetters(['user', 'pastZakaat']),
    totalPages () {
      return Math.ceil(this.pastZakaat.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    ...mapActions(['deleteZakaatDetails']),
    goToPage (val) {
      this.currentPage = val - 1
    },
    showDetails (val) {
      this.currentKey = this.start + val
      this.currentItem = this.pastZakaat[this.currentKey]
      // this.currentItem = this.pastZakaat[val]
      // this.currentKey = val
    },
    deleteOrder (val) {
      this.currentID = val
      this.isDeleteItem = true
    },
    async reallyDelete () {
      this.isDeleting = true
      await this.deleteZakaatDetails(this.currentID)
      this.isDeleting = false
      this.isDeleteItem = false
    },
    goForward (val) {
      this.currentItem = this.pastZakaat[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.pastZakaat[val - 1]
      this.currentKey = val - 1
    },
    close () {
      this.isDeleteItem = false
      this.currentID = null
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
